import { Form, Formik, FormikProps } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import theme from "../../../util/theme";
import { StyledField } from "../../ui/StyledField";
import { getUserProfile, login, registerGiftUser } from "../../../services/firebase";
import { mobileSize } from "../../../util/variables";
import { AppLoadingButton, AppText } from "../../ui";
import { newUserSchema } from "../../../util/yup-schemas";
import { navigate } from "gatsby";

interface FormikValuesProps {
  email: string;
  password: string;
}

// const setBrazeUserAndEmail = (uid: string, email: string, cid: string) => {
//   import("../../../services/braze").then(({ initialize, changeUser, getUser }) => {
//     initialize(BRAZE_API_KEY, { baseUrl: BRAZE_SDK_ENDPOINT });
//     changeUser(uid);
//     getUser()?.setEmail(email);
//     getUser()?.setCustomUserAttribute("Discount Coupon", cid ?? "no coupon found");
//     console.log("Braze user and email has been set");
//   });
// };

export const GiftAccountCouponForm: React.FC<{ createAccount: boolean }> = ({ createAccount }) => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: FormikValuesProps) => {
    const { email, password } = values;
    const storedAnswers = localStorage.getItem("onboardingAnswers");

    setLoading(true);

    if (createAccount) {
      await handleCreateAccount(email, password);
    } else {
      await handleLogin(email, password, storedAnswers);
    }

    setLoading(false);
  };

  const handleCreateAccount = async (email: string, password: string) => {
    try {
      const response = await registerGiftUser({ email, password });
      if (response.error) {
        alert(response.error);
        return;
      }
      navigate("/onboarding/questions/?fromGift=true");
    } catch (error) {
      alert(error);
    }
  };

  const handleLogin = async (email: string, password: string, storedAnswers: string | null) => {
    try {
      const res = await login({ email, password });
      if (!res) {
        return;
      }
    } catch (err) {
      alert(`Incorrect email or password. Please try again.`);
      return;
    }

    const response = await getUserProfile();
    if (response.subscription_status !== "No active subscription") {
      alert(
        `You’ve already purchased this product. Please contact info@imprintapp.com if you have any questions or issues.`,
      );
      return;
    }

    if (storedAnswers) {
      navigate("/gift/redeem");
    } else {
      navigate("/onboarding/questions/?fromGift=true");
    }
  };

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
      }}
      validationSchema={newUserSchema}
      onSubmit={handleSubmit}
    >
      {(formik: FormikProps<FormikValuesProps>) => (
        <Form>
          <FormContainer>
            <Title>{createAccount ? "Create an Account" : "Log in"}</Title>
            <StyledField
              name="email"
              type="email"
              onFocus={() => {
                // discount_flow_email_tapped();
              }}
            />
            <StyledField
              value={formik.values.password}
              name="password"
              type="password"
              onFocus={() => {
                // discount_flow_password_tapped();
              }}
            />
            <HideDiv showDesktop>
              <AppLoadingButton
                disabled={
                  !formik.values.password ||
                  !!formik.errors.password ||
                  !formik.values.email ||
                  !!formik.errors.email
                }
                loading={loading}
                type="submit"
              >
                {createAccount ? "Create Account" : "Log in"}
              </AppLoadingButton>
            </HideDiv>
          </FormContainer>
          <HideDiv showMobile>
            <AppLoadingButton
              disabled={
                !formik.values.password ||
                !!formik.errors.password ||
                !formik.values.email ||
                !!formik.errors.email
              }
              loading={loading}
              type="submit"
              style={{ border: `2px solid ${theme.WHITE_COLOR}` }}
            >
              {createAccount ? "Create Account" : "Login"}
            </AppLoadingButton>
          </HideDiv>
        </Form>
      )}
    </Formik>
  );
};

const Title = styled.h2`
  margin: 0 0 32px 0;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 24px;
  font-weight: 500;
  color: ${theme.BLACK_COLOR};
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  max-width: 1000px;
  width: 90vw;
  padding: 80px;
  padding-bottom: 120px;
  border-radius: 16px;
  background-color: #fafafa;
  box-shadow: 0px 1.58px 11px rgba(44, 39, 56, 0.14);

  @media ${mobileSize} {
    padding: 40px 20px;
    margin-bottom: 24px;
    /* min-height: 400px; */
  }
`;

const ChangeFormButton = styled.button`
  border: none;
  background: none;
  font-family: ${theme.SECONDARY_FONT};
  font-size: 14px;
  color: ${theme.SLATE_COLOR_2};
  text-decoration: underline;
  font-weight: bold;
`;

const ChangeFormText = styled(AppText as any)`
  font-size: 14px;
  color: ${theme.SLATE_COLOR_2};
  text-align: center;
  margin-top: 16px;
`;

const HideDiv = styled.div<{ showDesktop?: boolean; showMobile?: boolean }>`
  display: ${props => (props.showDesktop ? "block" : "none")};
  @media ${mobileSize} {
    display: ${props => (props.showDesktop ? "none" : "block")};
  }
`;

import React from "react";
import { GiftAccountCouponForm } from "./GiftAccountCouponForm";
import { AppLogo } from "../../../components/ui";
import { Content, FlexContainer, LogoContainer, Title } from "./DiscountSSOPage";

export const GiftEmailPage = ({ createAccount }: { createAccount: boolean }) => {
  return (
    <FlexContainer>
      <LogoContainer>
        <AppLogo size="medium" />
      </LogoContainer>
      <Content>
        <Title>Unlock the Future of Learning</Title>
        <GiftAccountCouponForm createAccount={createAccount} />
      </Content>
    </FlexContainer>
  );
};

import React from "react";
import { BasicLayout } from "../../../../layouts/BasicLayout";
import { GiftEmailPage } from "../../../../components/PageSegments/subscription/GiftEmailPage";

export default (data: any) => {
  const { search } = data.location;
  const params = new URLSearchParams(search);
  const createAccount = params.get("createAccount") === "true";

  return (
    <BasicLayout noIndex>
      <GiftEmailPage createAccount={createAccount} />
    </BasicLayout>
  );
};
